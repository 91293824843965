import { io, Socket } from "socket.io-client";
import {analysisStatusUpdateLinks, ClientToServerEvents, ServerToClientEvents} from "../../src/types/socketIo.js";
import { GeneratedResultType } from "../../src/types/results.js";
import {Dict} from "../../src/types/dict.js";
import Mustache from 'mustache';
import {getPageReportId} from "./reportId.js";

const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io({
	reconnectionDelay: 2000,
	reconnectionDelayMax: 10000,
	randomizationFactor: 1,
	/*extraHeaders: {
		token: "..."
	}*/
});

let statusNames: Dict<string> | null | undefined = null;
socket.on('connect', async () => {
	statusNames = await getStatusNames()
});

socket.on('analysisStatusUpdate', function (reportId: string, status: GeneratedResultType['status'], links:analysisStatusUpdateLinks) {
	if (getPageReportId() !== reportId) return;

	updateStatuses(status, links);
});

function updateStatuses(status: GeneratedResultType['status'], links:analysisStatusUpdateLinks) {
	const ulElement = document.querySelector('#status-list');
	if (!ulElement) {
		throw new Error("NO_STATUS_LIST");
	}

	// console.debug(pageReportId, status, links);
	for (const [name, value] of Object.entries(status)) {
		let statusElement = ulElement.querySelector<HTMLElement>(`#status_${name}`),
			liElement = statusElement?.parentElement
		;
		if (statusElement && !liElement) {
			throw new Error('NO_PARENT_ELEMENT');
		}
		if (!statusElement || (statusElement && links[name] && !liElement?.querySelector('a'))) {
			const template_status = document.querySelector('#status_template')?.innerHTML;
			if (!template_status) {
				throw new Error('STATUS_TEMPLATE_NOT_FOUND');
			}

			const renderedHtml = Mustache.render(template_status, {
				class: value === true ? "txt_good" : (typeof value === "string" ? "txt_bad" : ""),
				name: name,
				statusName: statusNames && statusNames[name] ? statusNames[name] : name,
				link: name in links ? links[name].link : null,
				linkId: name in links ? links[name].id : null,
				text: value === true ? 'OK' : (
					typeof value === "string" ?
						(name === 'pageData' ? value : 'Erreur')
						:
						'<span class="loader" title="En cours..."></span>'
				)
			});
			if (statusElement && liElement) {
				liElement.outerHTML = renderedHtml;
			} else {
				ulElement.insertAdjacentHTML('beforeend', renderedHtml);
			}
			continue;
		}
		if (!statusElement || !liElement) {
			continue;
		}

		liElement.classList.toggle('txt_good', value === true);
		liElement.classList.toggle('txt_bad', typeof value === "string");
		statusElement.innerHTML = value === true ? 'OK' : (typeof value === "string" ? "Erreur" : '<span class="loader" title="En cours..."></span>');
	}
}
socket.on("reportComplete", (reportId: string) => {
	if (getPageReportId() !== reportId) {
		return;
	}
	location.reload();
});

export async function ping() {
	return new Promise(resolve => {
		socket.emit('ping', resolve);
	})
}

export async function getStatusNames(): Promise<Dict<string>> {
	return new Promise(resolve => {
		socket.emit('getStatusNames', resolve);
	})
}
